import React, {Component} from "react";
import "./App.css";
// import Navbar from "./Components/Navbar";
// import Section from "./Components/Section";
import Text from "./Components/Text";
import EmailSubscribe from "./Components/EmailSubscribe";
import Logo from "./Components/Logo";
import Synths from "./Components/Synths";
import Social from "./Components/Social";


class App extends Component {
    render() {
        return (
            <div className="content">
                <Logo />
                <Text />
                <Synths />
                <Social />
                <EmailSubscribe />
            </div>
        );
    }
}

export default App;
