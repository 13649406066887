import React from 'react';

function Text() {
    return (
        <div className="text">
            <p>
                JAMMING ON-LINE WITH SYNTHS
            </p>
            
        </div>
    );
}

export default Text;
