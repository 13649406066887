import React, { Component } from 'react';
import * as EmailValidator from 'email-validator';

class EmailSubscribe extends Component {
    constructor(props) {
        super(props);
        this.state = { email: '' };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ email: event.target.value })

        //console.log(this.state.email)
    }

    handleSubmit(event) {
        event.preventDefault();

        if(EmailValidator.validate("test@email.com")){
            const email = this.state.email
            console.log("Email: " + email)
        }else {
            console.log(`Subscription email: ${this.state.email}`)
        }


        // Example: API call to backend
    }

    render() {
        return (
            <div className="subscribe">
                <form onSubmit={this.handleSubmit}>
                    <input
                        type="email"
                        placeholder="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                    />
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        );
    }
}

export default EmailSubscribe;
