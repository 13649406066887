import React from 'react';
import styled from 'styled-components';
import instagram from "../assets/images/instagram-logo-black.png";

function Social() {
    return (
        <div className="social-media">
            <a href="http://instagram.com/_u/lokojam_/">
                <img src={instagram} alt="Instagram"/>
            </a>
        </div>
    );
}

export default Social;
