import React from 'react';
import styled from 'styled-components';
import _909 from "../assets/images/909.png";
import _808 from "../assets/images/808.png";
import _tanguy from '../assets/images/tanguy.png';

const drum909 = window.location.href.includes('localhost') ? 'http://localhost:8000' : 'http://909-drummachine.s3-website.eu-west-2.amazonaws.com/';
const drum808 = window.location.href.includes('localhost') ? 'http://localhost:3001' : 'http://808-drummachine.s3-website.eu-west-2.amazonaws.com/';
const tanguy = 'http://tanguysynth.com/';

function Synths() {
    return (
        <div className="synths">
            <Synth>
                <Link href={drum909}>
                    <Title>Play 909</Title>
                    <Drum src={_909} alt="909"/>
                </Link>
            </Synth>
            <Synth>
                <Link href={drum808}>
                    <Title>Play 808</Title>
                    <Drum src={_808} alt="303"/>
                </Link>
            </Synth>
            <Synth>
                <Link href={tanguy}>
                    <Title>Play Modular</Title>
                    <Drum src={_tanguy} alt="Modular"/>
                </Link>
            </Synth>
        </div>
    );
}


const Synth = styled.div`
    @media only screen and (max-width: 768px){
        width: 21em;
        height: 234px;
    }

    @media only screen and (min-width: 768px){

        width: 34em;
        height: 300px;
    }
    
    display: inline-block;
`;

const Title = styled.p`
    font-size: 1.5em;
    text-decoration: none;
    text-align: center;
    color: black;
    font-weight: bold;
    p:hover: text-decoration: underline;
`;

const Link = styled.a`
    &:hover ${Title} {
        text-decoration: underline;
    };
    margin: 10px auto 0;
    width: 19em;
    display: block;
    
     
`;

const Drum = styled.img`
    border: 2px solid #333333;
    margin: 0 auto;
`;

export default Synths;
