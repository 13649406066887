import React from 'react';

import logo from "../assets/images/lokojam-logo.png";
import logoSmall from "../assets/images/lokojam-logo-small.png";

function Logo () {
    return (
        <div className="container">
            <picture>
                <source
                    media="(max-width: 768px)"
                    srcSet={logoSmall}/>
                <img src={logo} alt="logo"/>
            </picture>
        </div>
    );
}

export default Logo;
